<template>
    <div>
        <el-row style="display: flex; align-items: center;">
            <el-col :span="22" class="component-title">
                Assessments: <strong>{{$store.state.counsellorStudentName}}</strong>
            </el-col>
            <el-col :span="2" v-if="!isMobile()">
                <profile-avatar></profile-avatar>
            </el-col>
        </el-row>
        <el-row>
            <el-tabs v-model="activeName">
                <el-tab-pane label="Career Test Answers" name="Career">
                    <el-row :style="isMobile()?{height: '75vh'}:{height: '85vh'}"
                            style="overflow-y: auto; overflow-x: hidden">
                        <data-tables :filters="careerFilters" :data="getCareerAnswers" :page-size="10"
                                     style="width: 100%;"
                                     :pagination-props="{ pageSizes: [5, 10, 20, 50] }">
                            <el-row slot="tool" style="margin: 10px 0">
                                <el-col :xl="5" :lg="5" :md="8" :sm="24" :xs="24">
                                    <el-input placeholder="Search" v-model="careerFilters[0].value"></el-input>
                                </el-col>
                            </el-row>
                            <el-table-column label="Question" :prop="'question'" width="auto"
                                             :sortable="true"></el-table-column>
                            <el-table-column label="Answer" :prop="'score'" width="auto"
                                             :sortable="true" :formatter="cellValueRenderer"></el-table-column>
                            <!--                            <el-table-column label="Sector" :prop="'sectors'" width="auto"-->
                            <!--                                             :sortable="true"></el-table-column>-->
                            <el-table-column label="Career Areas" :prop="'sub_sectors'" width="auto"
                                             :sortable="true"></el-table-column>
                        </data-tables>
                    </el-row>
                </el-tab-pane>
                <el-tab-pane label="Personality Test Answers" name="Personality">
                    <el-row :style="isMobile()?{height: '75vh'}:{height: '85vh'}"
                            style="overflow-y: auto; overflow-x: hidden">
                        <data-tables :filters="personalityFilters" :data="getPersonalityAnswers" :page-size="10"
                                     style="width: 100%;"
                                     :pagination-props="{ pageSizes: [5, 10, 20, 50] }">
                            <el-row slot="tool" style="margin: 10px 0">
                                <el-col :xl="5" :lg="5" :md="8" :sm="24" :xs="24">
                                    <el-input placeholder="Search" v-model="personalityFilters[0].value"></el-input>
                                </el-col>
                            </el-row>
                            <el-table-column label="Question 1" :prop="'question'" width="auto"
                                             :sortable="true"></el-table-column>
                            <el-table-column label="Personality 1" :prop="'personality'" width="auto"
                                             :sortable="true"></el-table-column>
                            <el-table-column label="Question 2" :prop="'related_question'" width="auto"
                                             :sortable="true"></el-table-column>
                            <el-table-column label="Personality 2" :prop="'related_question_personality'" width="auto"
                                             :sortable="true"></el-table-column>
                            <el-table-column label="Answer" :prop="'score'" width="auto"
                                             :cell-class-name="getPersonalityClassName"
                                             :formatter="cellValueRenderer"
                                             :sortable="true"></el-table-column>
                        </data-tables>
                    </el-row>
                </el-tab-pane>

            </el-tabs>
        </el-row>
    </div>
</template>

<script>
  import ProfileAvatar from '../../main_components/ProfileAvatar';

  export default {
    name: 'CounsellorAssessments',
    components: {ProfileAvatar},
    props: ['studentId', 'studentName'],
    data: function() {
      return {
        personalityAnswerMapping: {
          'like_both': 'Like Both',
          'dislike_both': 'Dislike Both',
          'q1_prefer': 'Q1 Prefer',
          'q1_really_prefer': 'Q1 Really Prefer',
          'q2_prefer': 'Q2 Prefer',
          'q2_really_prefer': 'Q2 Really Prefer',
        },
        activeName: 'Career',
        loadingScreen: false,
        careerTestAnswers: [],
        personalityTestAnswers: [],
        careerFilters: [
          {
            value: '',
            prop: ['question', 'score', 'sub_sectors'],
          }],
        personalityFilters: [
          {
            value: '',
            prop: ['question', 'related_question', 'personality', 'related_question_personality', 'score'],
          }],
      };
    },
    mounted() {
      let self = this;
      self.checkUserLoggedIn().then(response => {
        if (self.$store.state.isAdmin) {
          self.$router.push('/admin/');
        }
        if (self.studentId === undefined || self.studentName === undefined) {
          self.$router.push('/counsellor/home/');
        }
        self.$store.state.counsellorStudentId = self.studentId;
        self.$store.state.counsellorStudentName = self.studentName;
        self.getCareerTestAnswers();
        self.getPersonalityTestAnswers();
      }).catch(error => {
        error;
        self.$store.state.loadingScreen = false;
      });
    },
    computed: {
      getCareerAnswers: function() {
        return this.careerTestAnswers;
      },
      getPersonalityAnswers: function() {
        return this.personalityTestAnswers;
      },
    },
    methods: {
      getPersonalityClassName: function() {
        return 'personalityAnswerClass';
      },
      cellValueRenderer: function(row) {
        return this.toTitleCase(row.score.split('_').join(' '));
      },
      getCareerTestAnswers: function() {
        let self = this;
        let url = self.getBaseUrl() + '/recommendations/student/test1_answers/' + self.studentId + '/';
        $.ajax({
          url: url,
          beforeSend: function(request) {
            request.setRequestHeader('Authorization', 'Token ' + self.getAuthToken());
          },
          type: 'GET',
          success: function(response) {
            self.careerTestAnswers = response;
          },
          error: function(error) {
            console.log(error);
          },
        });
      }
      ,
      getPersonalityTestAnswers: function() {
        let self = this;
        let url = self.getBaseUrl() + '/recommendations/student/test2_answers/' + self.studentId + '/';
        $.ajax({
          url: url,
          beforeSend: function(request) {
            request.setRequestHeader('Authorization', 'Token ' + self.getAuthToken());
          },
          type: 'GET',
          success: function(response) {
            self.personalityTestAnswers = response;
          },
          error: function(error) {
            console.log(error);
          },
        });
      }
      ,
    },
  }
  ;
</script>

<style scoped>
    .personalityAnswerClass {
        font-weight: 900;
    }
</style>
